import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { getStatusColor } from 'constants/status'
import { initialState, fields, handlers } from 'models/repairPage'
import {
  Grid,
  Section,
  Button,
  Link,
  Center,
  StatusChip,
  Toolbar,
  Modal,
  Image,
  Divider,
} from 'components/core'
import Page from 'components/Page'
import RepairDrawer from 'views/RepairDrawer'
import {
  MdAdd,
  MdArrowBack,
  MdContentCopy,
  MdEdit,
  MdPrint,
} from 'react-icons/md'
import { renderDeleteLink } from 'utilities/app'
import RepairReceiptDrawer from './RepairReceiptDrawer'
import { useCallbackOnce } from 'hooks/useCallbackOnce'
import { Flex } from 'reflexbox'

export default ({ match, profile }) => {
  const { id } = match.params
  const url = new URL(window.location.href)
  const customerId = url.searchParams.get('customerId')
  const copyId = url.searchParams.get('copyId')
  const history = useHistory()
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState({}, message, profile))
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState()
  const [receiptOpen, setReceiptOpen] = useState(false)
  const [receiptValue, setReceiptValue] = useState()
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState(null)

  const handleOpen = (item) => {
    setValue(item)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setValue({})
  }

  const handleReceiptOpen = (item) => {
    setReceiptValue(item)
    setReceiptOpen(true)
  }

  const handleReceiptClose = () => {
    setReceiptOpen(false)
    setReceiptValue({})
  }

  const readonly = profile === 'view'
  const form = fields({
    profile,
    app,
    session,
    state,
    setState,
    message,
    action: { handleOpen, setPreviewOpen, setPreviewImage },
  })
  const handler = handlers({
    state,
    setState,
    app,
    session,
    message,
    history,
    id,
    copyId,
    customerId,
    profile,
  })

  useEffect(() => {
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, profile])

  return (
    <>
      <Page
        profile={profile}
        navId="repairList"
        title={`repair.title.${profile}`}
        titleExtra={
          <StatusChip
            show={readonly}
            ml={2}
            label={`repair.status.${state.status}`}
            color={getStatusColor(state.status)}
            bg="light.1"
          />
        }
        action={
          <Center>
            <Link
              variant="link"
              icon={<MdArrowBack />}
              text="btn.back"
              href="/repair"
            />
            <Link
              ml={3}
              show={readonly}
              variant="link"
              icon={<MdContentCopy />}
              tooltip="btn.copy"
              href={`/repair/add?copyId=${id}`}
            />
            <Link
              ml={3}
              show={readonly}
              variant="link"
              icon={<MdPrint />}
              tooltip="btn.print"
              onClick={handler.handlePrint}
            />
            {renderDeleteLink({
              module: 'repair',
              session,
              readonly,
              status: state.status,
              onSubmit: handler.handleDelete,
            })}
            <Link
              ml={3}
              show={readonly}
              disabled={state.isMigration || state.status === 'INACTIVE'}
              variant="link"
              icon={<MdEdit />}
              tooltip="btn.edit"
              href={`/repair/${id}/edit`}
            />
            <Button
              ml={3}
              show={!readonly}
              variant="primary"
              text="btn.save"
              type="submit"
            />
          </Center>
        }
        showFooterAction
        as="form"
        onSubmit={useCallbackOnce(handler.handleSubmit)}
        audit={{
          createdAt: state.createdAt,
          createdBy: state.createdBy,
          updatedAt: state.updatedAt,
          updatedBy: state.updatedBy,
        }}
      >
        <Grid cols={['1fr', '1fr 1fr 1fr']} gap={3}>
          <Section>
            {form.id}
            {form.repairDate}
            {form.repairTime}
            {form.shippingMemo}
            {form.transDate}
            {form.receiptType}
            {form.receiptTitle}
            {form.ein}
          </Section>
          <Section>
            {form.support}
            {form.dealer}
            {form.recipientContact}
            {form.recipientAddress}
            {form.recipientPhone}
            {form.recipientCellphone}
            {form.recipientEmail}
            {form.collectAmount}
          </Section>
          <Section>
            {form.tech}
            {form.type}
            {form.symptom}
            {form.solution}
            {form.memo}
            {form.tag}
            {form.commission}
          </Section>
        </Grid>
        <Section mt={5} title="repair.section.product">
          <Toolbar show={!readonly}>
            <Button
              variant="default"
              icon={<MdAdd />}
              text="btn.add"
              onClick={() => handleOpen({ index: -1 })}
            />
          </Toolbar>
          {form.product}
        </Section>
        <Section mt={5} title="repair.section.image">
          {form.images}
        </Section>
        <Section show={readonly} mt={5} title="repair.section.part">
          <Toolbar>
            <Link
              variant="primaryLink"
              p={2}
              fontSize={1}
              icon={<MdAdd />}
              text="btn.add"
              href={`${process.env.REACT_APP_REPAIR_URL}/sell/add?repairId=${state.id}`}
            />
          </Toolbar>
          {form.parts}
        </Section>
        <Section show={readonly} mt={5} title="repair.section.receipt">
          <Toolbar>
            <Button
              variant="default"
              icon={<MdAdd />}
              text="btn.add"
              onClick={handleReceiptOpen}
            />
          </Toolbar>
          {form.receipts}
        </Section>
      </Page>
      <RepairDrawer
        open={open}
        value={value}
        data={{
          products: state.products,
          models: state.models,
          serialNos: state.serialNos,
          repairDates: state.repairDates,
        }}
        action={{
          handleOpen,
          handleClose,
          addItem: handler.addItem,
        }}
      />
      <RepairReceiptDrawer
        open={receiptOpen}
        value={receiptValue}
        data={{ id: state.id, hash: state.hash }}
        action={{
          handleClose: handleReceiptClose,
          handleLoad: handler.handleLoad,
        }}
      />
      <Modal open={previewOpen} onClose={() => setPreviewOpen(false)}>
        <Image {...previewImage} maxHeight="calc(100vh - 128px)" />
        <Divider bg="light.3" />
        <Flex justifyContent="flex-end" alignItems="center" mt={3}>
          <Button
            variant="secondary"
            text="btn.close"
            onClick={() => setPreviewOpen(false)}
          />
        </Flex>
      </Modal>
    </>
  )
}
