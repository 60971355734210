import React, { Suspense, lazy } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import {
  initializeState,
  getSelectOption,
  getMultiSelectOption,
  getSelectOptions,
  validateForm,
  showAddress,
  showDate,
  getDate,
  renderDefinition,
  renderSelectInput,
  renderDateInput,
  renderTextInput,
  renderAddressInput,
  renderPhoneInput,
  renderTable,
  renderTextAreaInput,
  renderNumberInput,
  renderCustomerLookup,
} from 'utilities/form'
import { request } from 'utilities/request'
import { getDiff } from 'utilities/list'
import { printHtml } from 'utilities/print'
import { handleDelete } from 'actions/ticket'
import {
  Center,
  Button,
  Link,
  LoadingIcon,
  DataPlaceholder,
} from 'components/core'
import { MdDelete, MdEdit } from 'react-icons/md'
import { filterLocations } from 'utilities/permission'
import { ALERT_ADD, CONFIRM_SET } from 'constants/actionType'
import { updateImages } from 'actions/image'
import { ImageThumb } from 'components/file'
import { Flex } from 'reflexbox'
import {
  checkAddressExists,
  checkPhoneExists,
  getCustomers,
} from 'actions/customer'
import { urlencode } from 'utilities/app'

const url = process.env.REACT_APP_STATIC_URL
const ImageDropzone = lazy(() => import('components/file/ImageDropzone'))

export const initialState = (value = {}, message, profile) => {
  const extra = value.extra || {}
  const dealers = getSelectOptions(value.dealers)
  const techs = getSelectOptions(value.techs)
  const supports = getSelectOptions(value.supports)
  const types = getSelectOptions(value.repairTypes)
  const repairTags = getSelectOptions(value.repairTags)
  const phoneName = extra.phoneName
  const cellphoneName = extra.cellphoneName
  let recipientPhone = extra.recipientPhone || ''
  let recipientCellphone = extra.recipientCellphone || ''

  if (profile === 'view') {
    if (phoneName) recipientPhone += ` [${phoneName}]`
    if (cellphoneName) recipientCellphone += ` [${cellphoneName}]`
  }

  const isMigration = extra.isMigration || false
  const parts = isMigration ? extra.parts : value.parts || []

  return {
    customerId: value.customerId,
    contact: value.contact || {},
    phoneName,
    cellphoneName,
    ticketCustomerId: extra.customerId,
    id: value.id || '',
    status: value.status || 'PENDING',
    isMigration,
    hash: value.hash,
    createdBy: value.createdBy,
    createdAt: value.createdAt,
    updatedBy: value.updatedBy,
    updatedAt: value.updatedAt,
    oldTicketItems: value.id ? cloneDeep(value.ticketItems || []) : [],
    ticketItems: value.ticketItems || [],
    parts,
    receipts: value.receipts || [],
    dealers,
    dealerName: value.fromLocationName || '',
    techs,
    techName: extra.techName || [],
    supports,
    supportName: extra.supportName || [],
    products: value.products || [],
    models: value.models || [],
    serialNos: value.serialNos || [],
    repairDates: value.repairDates || [],
    types,
    typeName: extra.typeName,
    repairTags,
    repairRemarks: value.repairRemarks,
    tagName: extra.tagName || [],
    remarkContent: getRemarkContent(
      extra.type,
      value.repairTypes,
      value.repairRemarks,
    ),
    recipientAddresses: value.recipientAddresses || [],
    recipientPhones: value.recipientPhones || [],
    recipientCellphones: value.recipientCellphones || [],
    isRecipientLookup: false,
    images: extra.images || [],
    imagesToAdd: [],
    imagesToDel: [],
    ...initializeState({
      transDate: getDate(value.transDate) || '',
      repairTime: extra.repairTime || '',
      repairDate: showDate(extra.repairDate) || '',
      type: getSelectOption(types, extra.type),
      dealerId: getSelectOption(dealers, value.fromLocationId),
      recipientContact: extra.recipientContact || '',
      recipientAddress: extra.recipientAddress || '',
      recipientPhone,
      recipientCellphone,
      recipientEmail: extra.recipientEmail || '',
      receiptType: getReceiptType(extra.receiptType, message),
      receiptTitle: extra.receiptTitle || '',
      ein: extra.ein || '',
      techId: getMultiSelectOption(techs, extra.techId),
      supportId: getSelectOption(supports, extra.supportId),
      symptom: extra.symptom || '',
      solution: extra.solution || '',
      memo: extra.memo || '',
      shippingMemo: extra.shippingMemo || '',
      tagId: getMultiSelectOption(repairTags, extra.tagId),
      commission: extra.commission || 0,
      collectAmount: extra.collectAmount || 0,
    }),
  }
}

function getReceiptTypes(message) {
  return [
    {
      value: 'INDIVIDUAL',
      label: message({ id: 'repair.receiptType.INDIVIDUAL' }),
    },
    {
      value: 'BUSINESS',
      label: message({ id: 'repair.receiptType.BUSINESS' }),
    },
  ]
}

function getReceiptType(value, message) {
  if (!value) return {}
  return { value, label: message({ id: `repair.receiptType.${value}` }) }
}

const validation = (hasCustomer) => {
  const result = {
    recipientContact: [{ type: 'required', message: 'error.required' }],
    recipientAddress: [
      { type: 'required', message: 'error.required' },
      { type: 'address', message: 'error.invalidAddress' },
    ],
    supportId: [{ type: 'required', message: 'error.required' }],
    type: [{ type: 'required', message: 'error.required' }],
    ticketItems: [{ type: 'required', message: 'error.required' }],
  }
  if (hasCustomer) {
    result.recipientAddress = [{ type: 'required', message: 'error.required' }]
  }
  return result
}

const crossValidation = [
  {
    fields: ['recipientPhone', 'recipientCellphone'],
    type: 'required',
    message: 'dispatch.error.phoneRequired',
  },
]

export const fields = ({
  profile,
  format,
  app,
  session,
  state,
  setState,
  message,
  action,
}) => {
  const hasCustomer = !!state.customerId
  const commonProps = {
    profile,
    format,
    state,
    setState,
    validation: validation(hasCustomer),
    moduleName: 'repair',
  }
  const definition = (inputProps) =>
    renderDefinition({ ...commonProps, ...inputProps })
  const selectInput = (inputProps) =>
    renderSelectInput({ ...commonProps, ...inputProps })
  const dateInput = (inputProps) =>
    renderDateInput({ ...commonProps, ...inputProps })
  const textInput = (inputProps) =>
    renderTextInput({ ...commonProps, ...inputProps })
  const addressInput = (inputProps) =>
    renderAddressInput({ ...commonProps, ...inputProps, message })
  const phoneInput = (inputProps) =>
    renderPhoneInput({ ...commonProps, ...inputProps, message })
  const textAreaInput = (inputProps) =>
    renderTextAreaInput({ ...commonProps, ...inputProps })
  const numberInput = (inputProps) =>
    renderNumberInput({ ...commonProps, ...inputProps })
  const tableInput = (inputProps) =>
    renderTable({ ...commonProps, ...inputProps })
  const customerLookup = (inputProps) =>
    renderCustomerLookup({ ...commonProps, ...inputProps })

  return {
    id: definition({ id: 'id', label: 'field.ticketId' }),
    type: selectInput({
      id: 'type',
      isClearable: false,
      valKey: 'typeName',
      options: state.types,
    }),
    transDate: dateInput({ id: 'transDate', min: '1970-01-01' }),
    repairTime: textInput({
      id: 'repairTime',
      placeholder: 'placeholder.time',
    }),
    repairDate: dateInput({ id: 'repairDate', min: '1970-01-01' }),
    dealer: selectInput({
      id: 'dealerId',
      valKey: 'dealerName',
      isClearable: false,
      options: state.dealers,
    }),
    // recipientContact: textInput({
    //   id: 'recipientContact',
    //   href: `${process.env.REACT_APP_STOCK_URL}/customer/${state.ticketCustomerId}/view`,
    //   target: '_blank',
    // }),
    recipientContact: customerLookup({
      id: 'recipientContact',
      href: `${process.env.REACT_APP_STOCK_URL}/customer/${state.ticketCustomerId}/view`,
      target: '_blank',
      disabled: !!state.customerId,
      callback: (value) => {
        setState({
          ...state,
          isRecipientLookup: !!value.id,
          customerId: value.id || '',
          recipientContact: value.name || '',
          recipientPhone: value.phone || '',
          recipientCellphone: value.cellphone || '',
          recipientAddress: value.address || {},
          phoneName: value.phoneName || '',
          cellphoneName: value.cellphoneName || '',
        })
        return true
      },
    }),
    recipientAddress:
      state.recipientAddresses.length > 0
        ? selectInput({
            id: 'recipientAddress',
            isClearable: false,
            options: state.recipientAddresses,
          })
        : addressInput({ id: 'recipientAddress' }),
    recipientPhone:
      state.recipientPhones.length > 0
        ? selectInput({
            id: 'recipientPhone',
            options: state.recipientPhones,
            callback: ({ value }) => ({ phoneName: state.contact[value] }),
          })
        : phoneInput({ id: 'recipientPhone' }),
    recipientCellphone:
      state.recipientCellphones.length > 0
        ? selectInput({
            id: 'recipientCellphone',
            options: state.recipientCellphones,
            callback: ({ value }) => ({ cellphoneName: state.contact[value] }),
          })
        : phoneInput({ id: 'recipientCellphone', type: 'cellphone' }),
    recipientEmail: textInput({ id: 'recipientEmail' }),
    receiptType: selectInput({
      id: 'receiptType',
      options: getReceiptTypes(message),
    }),
    receiptTitle: textInput({ id: 'receiptTitle' }),
    ein: textInput({ id: 'ein' }),
    tech: selectInput({
      id: 'techId',
      valKey: 'techName',
      isClearable: false,
      isMulti: true,
      options: state.techs,
    }),
    support: selectInput({
      id: 'supportId',
      valKey: 'supportName',
      isClearable: false,
      options: state.supports,
    }),
    symptom: textAreaInput({ id: 'symptom' }),
    solution: textAreaInput({ id: 'solution' }),
    memo: textAreaInput({ id: 'memo' }),
    shippingMemo: textAreaInput({ id: 'shippingMemo' }),
    tag: selectInput({
      id: 'tagId',
      valKey: 'tagName',
      isMulti: true,
      options: state.repairTags,
    }),
    commission: numberInput({ id: 'commission', type: 'decimal' }),
    collectAmount: numberInput({ id: 'collectAmount' }),
    images: renderImageField({
      format,
      profile,
      app,
      session,
      state,
      setState,
      action,
    }),
    product: tableInput({
      id: 'ticketItems',
      columns: [
        {
          id: 'productVariantName',
          label: 'repair.field.spu',
        },
        {
          id: 'model',
          label: 'repair.field.model',
          render: ({ row }) => row.extra?.model,
        },
        {
          id: 'serialNo',
          label: 'repair.field.serialNo',
          render: ({ row }) => row.extra?.serialNo,
        },
        {
          id: 'repairDate',
          label: 'repair.field.installDate',
          render: ({ row }) => row.extra?.repairDate,
        },
        {
          id: 'memo',
          label: 'repair.field.productMemo',
          render: ({ row }) => row.extra?.memo,
        },
        {
          id: 'actions',
          align: 'right',
          noWrap: true,
          format: ['html'],
          show: profile !== 'view',
          render: ({ row, index }) => (
            <>
              <Button
                mr={2}
                variant="icon"
                icon={<MdEdit />}
                onClick={() => action.handleOpen({ ...row, index })}
              />
              <Button
                icon={<MdDelete />}
                variant="icon"
                onClick={() => {
                  const ticketItems = [...state.ticketItems]
                  ticketItems.splice(index, 1)
                  setState({ ...state, ticketItems })
                }}
              />
            </>
          ),
        },
      ],
    }),
    parts: tableInput({
      id: 'parts',
      columns: [
        {
          id: 'transDate',
          label: 'field.date',
          render: ({ row }) => showDate(row.transDate),
        },
        {
          id: 'id',
          label: 'field.ticketId',
          renderHtml: ({ row }) => (
            <Link
              variant="primaryLink"
              href={`${process.env.REACT_APP_REPAIR_URL}/sell/${row.id}/view`}
              target="_blank"
            >
              {row.id}
            </Link>
          ),
        },
        {
          id: 'productVariantName',
          label: 'repair.field.partName',
        },
        {
          id: 'sku',
          label: 'repair.field.partSku',
        },
        {
          id: 'quantity',
          label: 'field.quantity',
        },
        {
          id: 'price',
          label: 'field.subTotal',
        },
      ],
    }),
    receipts: tableInput({
      id: 'receipts',
      columns: [
        {
          id: 'transDate',
          label: 'field.date',
          render: ({ row }) => showDate(row.transDate),
        },
        {
          id: 'receiptNo',
          label: 'repair.field.receiptNo',
          render: ({ row }) => row.receiptNo,
        },
        {
          id: 'receiptAddress',
          label: 'repair.field.receiptAddress',
          render: ({ row }) => showAddress(row.receiptAddress, message),
        },
        {
          id: 'price',
          label: 'field.price',
        },
        {
          id: 'memo',
          label: 'repair.field.memo',
          render: ({ row }) => row.memo,
        },
        {
          id: 'actions',
          align: 'right',
          noWrap: true,
          render: ({ index }) => (
            <>
              <Button
                icon={<MdDelete />}
                variant="icon"
                onClick={(event) => {
                  const name = 'module.repairReceipt'
                  const item = {
                    open: true,
                    title: { id: 'title.delete', texts: { name } },
                    text: { id: 'message.delete', texts: { name } },
                    onSubmit: async () => {
                      const [ok] = await deleteReceipt({
                        session,
                        app,
                        state,
                        index,
                      })
                      if (!ok) return false

                      const profile = 'view'
                      const data = await getData({
                        app,
                        session,
                        id: state.id,
                        profile,
                      })
                      setState(initialState(data, message, profile))
                      return true
                    },
                  }
                  session.dispatch({ type: CONFIRM_SET, item })
                }}
              />
            </>
          ),
        },
      ],
    }),
  }
}

function renderImageField({
  format,
  profile,
  app,
  session,
  state,
  setState,
  action,
}) {
  if (format === 'print') return { id: 'images' }
  if (profile === 'view') {
    return (
      <Flex flexWrap="wrap" alignContent="center">
        {state.images.length === 0 ? (
          <DataPlaceholder />
        ) : (
          state.images.map((item) => {
            const { merchantId } = app.state.staff
            const src = `${url}/${merchantId}/${state.id}/${urlencode(item)}`
            return (
              <ImageThumb
                key={item}
                src={src}
                onView={() => {
                  action.setPreviewOpen(true)
                  action.setPreviewImage({ src, alt: item })
                }}
              />
            )
          })
        )}
      </Flex>
    )
  }

  return (
    <Suspense
      fallback={
        <Center>
          <LoadingIcon />
        </Center>
      }
    >
      <ImageDropzone
        baseUrl={`${url}/${app.state.staff.merchantId}/${state.id}/`}
        value={state.images || []}
        onUpload={(files) => {
          const images = [...state.images, ...files]
          const imagesToAdd = [...state.imagesToAdd, ...files]
          setState({ ...state, images, imagesToAdd })
        }}
        onDelete={(file, index) => {
          const images = [...state.images]
          images.splice(index, 1)
          const imagesToDel = [...state.imagesToDel]
          if (!file.preview) imagesToDel.push(file)
          setState({ ...state, images, imagesToDel })
        }}
        onError={(errorMessages) =>
          errorMessages.forEach((item) => {
            session.dispatch({
              type: ALERT_ADD,
              item: { type: 'error', message: item },
            })
          })
        }
        onDrag={(images) => {
          setState({ ...state, images })
        }}
      />
    </Suspense>
  )
}

export const handlers = ({
  state,
  setState,
  session,
  app,
  history,
  message,
  id,
  copyId,
  customerId,
  profile,
}) => ({
  handleLoad: async () => {
    if (copyId) id = copyId
    const data = await getData({ app, session, id, profile })
    if (customerId) {
      data.customerId = customerId
      const resp = await getCustomerData({ app, session, customerId })
      setCustomerData(data, resp, message)
    }
    if (copyId) {
      data.id = null
      data.transDate = null
      data.status = null
      data.createdBy = null
      data.updatedAt = null
      data.ticketItems = data.ticketItems.map((item) => ({ ...item, id: null }))
      data.extra = {
        ...data.extra,
        repairDate: null,
        repairTime: null,
        shippingMemo: null,
        type: null,
        supportId: null,
        tagId: null,
        commission: null,
        images: [],
      }
    }
    setState(initialState(data, message, profile))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    const hasCustomer = !!state.customerId
    const isValid = validateForm({
      session,
      state,
      setState,
      validation: validation(hasCustomer),
      crossValidation,
    })
    if (!isValid) return

    const [ok, data] = id
      ? await editRepair(state, app, session, history)
      : await addRepair(state, app, session, history)
    if (!ok) return

    if (!id) id = data.addRepairTicket
    const imageOk = await updateImages(app, session, state, id)
    if (!imageOk) {
      if (!id) history.push(`/repair/${id}/edit`)
      return
    }

    history.push(`/repair/${id}/view`)
  },
  addItem: ({ index, ...value }) => {
    const ticketItems = [...state.ticketItems]
    if (index === -1) {
      ticketItems.push(value)
    } else {
      ticketItems.splice(index, 1, value)
    }
    setState({ ...state, ticketItems })
  },
  handleDelete: async () => {
    const { hash } = state
    const ok = await handleDelete('repair', { session, app, id, hash })
    if (!ok) return false

    history.push('/repair')
    return true
  },
  handlePrint: () => {
    const { title, header, footer, content } = getPrintData({
      app,
      state,
      message,
    })
    printHtml({ title, header, footer, content, message })
  },
})

export function getPrintData({ app, state, message }) {
  const title = { id: 'repair.title.print', values: { ticketId: state.id } }
  const header = {
    title,
    address: 'ticket.address',
    phone: 'ticket.phone',
  }
  const footer = [
    { label: 'ticket.manager', value: '__INPUT__' },
    { label: 'ticket.warehouse', value: '__INPUT__' },
    { label: 'ticket.technician', value: '__INPUT__' },
    { label: 'ticket.customer', value: '__INPUT__' },
  ]
  const form = fields({
    profile: 'view',
    format: 'print',
    state,
    app,
    message,
  })
  const skipFlds = [
    'tagId',
    'commission',
    'solution',
    'images',
    'ticketItems',
    'parts',
    'receipts',
  ]
  const field = Object.values(form).filter(({ id }) => !skipFlds.includes(id))
  const receiptType = field.find(({ id }) => id === 'receiptType')
  receiptType.value = getReceiptTypes(message)
    .map((item) => {
      const checked = item.value === state.receiptType?.value
      return `${checked ? '&#9745;' : '&#9744;'} ${item.label}`
    })
    .join('&nbsp;&nbsp;')
  const notes = [
    // '&bull; 保固期外基本維修費$800元',
    // '&bull; 服務維修LINE ID: @ASERVICE',
    '&#9744; 本人確認貴公司人員對於本次維修/服務內容均已充分告知。本人如有任何疑問(包含：產品運作機能是否正常、安裝/維修過程中是否損傷本人任何財產…等)，均已當場反映並由貴公司人員拍照、記載於本維修單內，確認無誤。',
  ]
  const content = [
    {
      type: 'field',
      value: field,
      display: 'inline',
      areas: `
      'transDate repairTime shippingMemo'
      'id repairDate type'
      'recipientContact recipientPhone recipientCellphone'
      'recipientAddress recipientAddress recipientEmail'
      'dealerId techId supportId'
      'receiptType receiptTitle ein'
      'collectAmount collectAmount collectAmount'
      'symptom symptom symptom'
      'memo memo memo'
    `,
    },
    // { type: 'space' },
    { type: 'list', value: form.product },
    { type: 'text', value: notes },
  ]
  if (state.remarkContent) {
    content.push({ type: 'break' })
    content.push({ type: 'block', value: state.remarkContent })
  }
  return { title, header, footer, content }
}

async function getData({ app, session, id, profile }) {
  const staffInput = { type: ['CUSTOMER_SUPPORT', 'TECHNICIAN'] }
  const locationInput = { type: ['DEALER'] }
  const partInput = { parentId: id, joinItem: true }
  const productInput = { status: ['ACTIVE', 'INACTIVE'] }
  const variables = { id, locationInput, staffInput, partInput, productInput }
  const partQuery = `
    partSellTickets(input: $partInput) {
      id
      transDate
      productVariantName
      sku
      price
      quantity
      extra
      status
    }
  `
  const query = `
    query($id: ID, $locationInput: LocationQueryInput, $staffInput: StaffQueryInput, $partInput: TicketQueryInput, $productInput: ProductQueryInput) {
      locations(input: $locationInput) {
        id
        parentId
        name
        type
        extra
      }
      staffs(input: $staffInput) {
        id
        name
        type
      }
      productVariants(input: $productInput) {
        id
        name
        sku
        barcode
      }
      repairTypes {
        id
        name
        extra
      }
      repairTags {
        id
        name
      }
      repairRemarks {
        id
        content
      }
      repairTicket(id: $id) {
        transDate
        fromLocationId
        fromLocationName
        extra
        status
        hash
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
      repairTicketItems(ticketId: $id) {
        id
        productVariantId
        productVariantName
        extra
      }
      ${id ? partQuery : ''}
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const {
    repairTypes,
    repairTags,
    repairRemarks,
    staffs,
    productVariants,
    partSellTickets = [],
  } = data
  const locations = filterLocations(app.state.staff, data.locations)
  const ticket = data.repairTicket || {}
  const ticketItems = data.repairTicketItems || {}
  const extra = ticket.extra || {}
  const receipts = extra.receipts || []
  // const remarkContent = getRemarkContent(extra.type, repairTypes, repairRemarks)
  return {
    id,
    extra,
    createdBy: ticket.createdBy,
    createdAt: ticket.createdAt,
    updatedBy: ticket.updatedBy,
    updatedAt: ticket.updatedAt,
    status: ticket.status,
    hash: ticket.hash,
    transDate: ticket.transDate,
    fromLocationId: ticket.fromLocationId,
    fromLocationName: ticket.fromLocationName,
    // repairTime: extra.repairTime,
    // repairDate: extra.repairDate,
    // recipientContact: extra.recipientContact,
    // recipientAddress: extra.recipientAddress,
    // recipientPhone: extra.recipientPhone,
    // recipientCellphone: extra.recipientCellphone,
    // recipientEmail: extra.recipientEmail,
    // receiptType: extra.receiptType,
    // receiptTitle: extra.receiptTitle,
    // ticketCustomerId: extra.customerId,
    // ein: extra.ein,
    // techId: extra.techId,
    // techName: extra.techName,
    // supportId: extra.supportId,
    // supportName: extra.supportName,
    // symptom: extra.symptom,
    // solution: extra.solution,
    // memo: extra.memo,
    // shippingMemo: extra.shippingMemo,
    // type: extra.type,
    // tagId: extra.tagId,
    // tagName: extra.tagName,
    // commission: extra.commission,
    // images: extra.images,
    // remarkContent,
    // oldTicketItems: cloneDeep(ticketItems),
    ticketItems,
    dealers: locations,
    techs: staffs.filter(({ type }) => type === 'TECHNICIAN'),
    supports: staffs.filter(({ type }) => type === 'CUSTOMER_SUPPORT'),
    products: productVariants,
    repairTypes,
    repairTags,
    repairRemarks,
    parts: partSellTickets.filter((item) => item.status !== 'INACTIVE'),
    receipts,
  }
}

function getRemarkContent(type, repairTypes = [], repairRemarks = []) {
  if (!type) return ''
  const repairType = repairTypes.find(({ id }) => id === type)
  const remarkId = repairType?.extra.remarkId
  const remark = repairRemarks.find(({ id }) => id === remarkId)
  return remark?.content
}

async function getCustomerData({ app, session, customerId }) {
  const variables = { id: customerId }
  const query = `
    query($id: ID!) {
      customer(id: $id) {
        id
        name
        phones
        cellphones
        addresses {
          zipcode
          city
          district
          street
          hasLift
        }
        extra
      }
      customerTickets(customerId: $id) {
        id
        ticketType
        transDate
        productVariantId
        productVariantName
        quantity
        price
        extra
        itemExtra
        status
      }
      customerProducts(id: $id) {
        ticketType
        transDate
        productVariantId
        productVariantName
        quantity
        price
        extra
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return null

  const products = [...data.customerTickets, ...data.customerProducts].filter(
    (item) => item.productVariantId !== '-1',
  )
  return { ...data.customer, products }
}

function setCustomerData(data, customer, message) {
  const { name, products, extra } = customer || {}
  const addresses = (customer.addresses || []).map((item) => {
    const address = showAddress(item, message)
    return { value: address, label: address, obj: item }
  })
  const phones = (customer.phones || []).map((item) => {
    return { value: item, label: item }
  })
  const cellphones = (customer.cellphones || []).map((item) => {
    return { value: item, label: item }
  })
  data.contact = extra ? extra.contact : {}
  data.extra.recipientContact = name
  data.recipientAddresses = addresses
  // data.recipientAddress = addresses.length > 0 ? addresses[0] : ''
  data.recipientPhones = phones
  // data.recipientPhone = phones.length > 0 ? phones[0] : ''
  data.recipientCellphones = cellphones
  // data.recipientCellphone = cellphones.length > 0 ? cellphones[0] : ''
  if (products.length > 0) {
    const productMap = products.reduce((result, item) => {
      result[item.productVariantId] = {
        id: item.productVariantId,
        name: item.productVariantName,
      }
      return result
    }, {})
    data.products = Object.values(productMap)

    const modelMap = products.reduce((result, item) => {
      const extra = item.id ? item.itemExtra : item.extra
      const { model } = extra
      if (!model) return result

      result[model] = { id: item.productVariantId, model }
      return result
    }, {})
    data.models = Object.values(modelMap)

    const serialMap = products.reduce((result, item) => {
      const extra = item.id ? item.itemExtra : item.extra
      const { serialNo } = extra
      if (!serialNo) return result

      result[serialNo] = { id: item.productVariantId, serialNo }
      return result
    }, {})
    data.serialNos = Object.values(serialMap)

    const repairMap = products.reduce((result, item) => {
      const extra = item.id ? item.itemExtra : item.extra
      const { repairDate } = extra
      if (!repairDate) return result

      result[repairDate] = { id: item.productVariantId, repairDate }
      return result
    }, {})
    data.repairDates = Object.values(repairMap)
  }
}

async function addRepair(state, app, session, history) {
  const customers = await getCustomers(state, app, session)
  const dups = customers.filter(
    (item) => item && item.name !== state.recipientContact,
  )

  if (!state.customerId && dups && dups.length > 0) {
    const dup = dups[0]
    session.dispatch({
      type: CONFIRM_SET,
      item: {
        open: true,
        title: 'customer.title.addDuplicate',
        text: {
          id: 'customer.message.addDuplicate',
          values: { name: dup.name, newName: state.recipientContact },
        },
        onSubmit: async () => {
          const [ok, data] = await addRepairTicket(state, app, session)
          if (!ok) return false

          const id = data.addRepairTicket
          const imageOk = await updateImages(app, session, state, id)
          if (!imageOk) return false

          history.push(`/repair/${id}/view`)
          return true
        },
      },
    })
    return [false]
  }

  return addRepairTicket(state, app, session)
}

async function addRepairTicket(state, app, session) {
  const variables = { input: getSubmitInput(state) }
  const query = `
    mutation($input: TicketInput!) {
      addRepairTicket(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editRepair(state, app, session, history) {
  const { recipientPhone, recipientCellphone, recipientAddress } = state
  const [customer = {}] = await getCustomers(state, app, session)
  const hasPhone = checkPhoneExists(customer.phones, recipientPhone)
  const hasCellphone = checkPhoneExists(customer.cellphones, recipientCellphone)
  const hasAddress = checkAddressExists(customer.addresses, recipientAddress)

  if (!hasPhone || !hasCellphone || !hasAddress) {
    session.dispatch({
      type: CONFIRM_SET,
      item: {
        open: true,
        title: 'customer.title.metaNotFound',
        text: 'customer.message.metaNotFound',
        onSubmit: async () => {
          const { id } = state
          const [ok] = await editRepairTicket(state, app, session)
          if (!ok) return false

          const imageOk = await updateImages(app, session, state, id)
          if (!imageOk) {
            if (!id) history.push(`/repair/${id}/edit`)
            return false
          }

          history.push(`/repair/${id}/view`)
          return true
        },
      },
    })
    return [false]
  }

  return editRepairTicket(state, app, session)
}

async function editRepairTicket(state, app, session) {
  const variables = { id: state.id, input: getSubmitInput(state) }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editRepairTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  const { customerId, id, hash, oldTicketItems } = state
  const ticketItems = state.ticketItems.map((item) => ({
    id: item.id,
    productVariantId: item.productVariantId,
    quantity: 1,
    extra: item.extra,
  }))
  const isKeyEqual = (item, newItem) => {
    return (
      item.productVariantId === newItem.productVariantId &&
      item.id === newItem.id
    )
  }
  const isValEqual = (item, newItem) => {
    if (item.extra.model !== newItem.extra.model) return false
    if (item.extra.serialNo !== newItem.extra.serialNo) return false
    if (item.extra.repairDate !== newItem.extra.repairDate) return false
    if (item.extra.memo !== newItem.extra.memo) return false
    return true
  }
  const diff = getDiff(oldTicketItems, ticketItems, isKeyEqual, isValEqual)
  const { recipientAddress, recipientPhone, recipientCellphone } = state
  const techId = state.techId || []
  const images = state.images.map((item) => item.name || item)
  return {
    id,
    hash,
    fromLocationId: state.dealerId.value,
    transDate: state.transDate,
    extra: {
      repairDate: state.repairDate,
      repairTime: state.repairTime,
      customerId,
      recipientContact: state.recipientContact,
      recipientAddress: recipientAddress?.obj || recipientAddress,
      recipientPhone: recipientPhone?.value || recipientPhone,
      recipientCellphone: recipientCellphone?.value || recipientCellphone,
      recipientEmail: state.recipientEmail,
      receiptType: state.receiptType?.value,
      receiptTitle: state.receiptTitle,
      phoneName: state.phoneName,
      cellphoneName: state.cellphoneName,
      ein: state.ein,
      techId: techId.map((item) => item.value),
      techName: techId.map((item) => item.label),
      supportId: state.supportId.value,
      supportName: state.supportId.label,
      symptom: state.symptom,
      solution: state.solution,
      memo: state.memo,
      shippingMemo: state.shippingMemo,
      type: state.type.value,
      typeName: state.type.label,
      tagId: state.tagId?.map((item) => item.value) || [],
      tagName: state.tagId?.map((item) => item.label) || [],
      commission: state.commission,
      collectAmount: state.collectAmount,
      images,
    },
    itemsToAdd: diff.added,
    itemsToEdit: diff.modified.map((item) => item.after),
    itemsToDel: diff.removed.map((item) => item.id),
  }
}

// function getAddress(addresses, address) {
//   const result = addresses.find((item) => item.value === address.value)
//   return result?.obj
// }

async function deleteReceipt({ session, app, state, index }) {
  const receipts = { itemsToDel: [index] }
  const input = { hash: state.hash, extra: { receipts } }
  const variables = { id: state.id, input }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editRepairTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}
