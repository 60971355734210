import React from 'react'
import { getLockStatusOptions, getStatusColor } from 'constants/status'
import { request } from 'utilities/request'
import { getSelectOptions, showAddress, showDate } from 'utilities/form'
import { textFilter, dateFilter, selectFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { StatusChip, Link, LinkButton } from 'components/core'
import { MdEdit, MdLock } from 'react-icons/md'
import { renderDeleteButton } from 'utilities/app'
import { handleDelete } from 'actions/ticket'
import { FILTER_SET } from 'constants/actionType'
import { printHtml } from 'utilities/print'
import { getNumericValue } from 'utilities/string'
import { Flex } from 'reflexbox'

export const filters = (data, message) => [
  {
    id: 'id',
    label: 'repair.field.id',
    input: textFilter,
  },
  {
    id: 'type',
    label: 'repair.field.type',
    input: (props) =>
      selectFilter({
        ...props,
        options: getSelectOptions(data.repairTypes),
      }),
  },
  {
    id: 'transDate',
    label: 'repair.field.transDate',
    input: dateFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'repairDate',
    label: 'repair.field.repairDate',
    input: dateFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'tech',
    label: 'field.tech',
    input: (props) =>
      selectFilter({
        ...props,
        options: [
          { value: '__EMPTY__', label: message({ id: 'message.empty' }) },
          ...getSelectOptions(data.techs),
        ],
      }),
  },
  {
    id: 'contact',
    label: 'field.contact',
    input: textFilter,
  },
  {
    id: 'phone',
    label: 'repair.field.phoneOrCell',
    input: textFilter,
  },
  {
    id: 'dealer',
    label: 'field.dealer',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: getSelectOptions(data.dealers),
      }),
  },
  {
    id: 'tag',
    label: 'repair.field.tagId',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: [
          { value: '__EMPTY__', label: message({ id: 'message.empty' }) },
          ...getSelectOptions(data.repairTags),
        ],
      }),
  },
  {
    id: 'model',
    label: 'repair.field.model',
    input: textFilter,
  },
  {
    id: 'product',
    label: 'field.productName',
    input: textFilter,
  },
  {
    id: 'partName',
    label: 'field.partName',
    input: textFilter,
  },
  {
    id: 'partSku',
    label: 'field.partSku',
    input: textFilter,
  },
  {
    id: 'status',
    label: 'field.status',
    input: (props) =>
      selectFilter({
        ...props,
        options: getStatusOptions(message),
      }),
  },
  {
    id: 'lockStatus',
    label: 'field.locked',
    input: (props) =>
      selectFilter({
        ...props,
        options: getLockStatusOptions(props.message),
      }),
  },
]

function getStatusOptions(message) {
  return [
    { value: 'ACTIVE', label: message({ id: 'repair.status.ACTIVE' }) },
    { value: 'INACTIVE', label: message({ id: 'repair.status.INACTIVE' }) },
    { value: 'PENDING', label: message({ id: 'repair.status.PENDING' }) },
  ]
}

export const columns = ({ session, app, message, action }) => [
  {
    id: 'id',
    label: 'repair.field.id',
    format: ['html', 'excel', 'print'],
    renderHtml: ({ row }) => (
      <Flex>
        <Link variant="primaryLink" href={`/repair/${row.id}/view`}>
          {row.id}
        </Link>
        {row.extra?.locked ? <MdLock color="#ffb445" /> : ''}
      </Flex>
    ),
  },
  {
    id: 'transDate',
    label: 'repair.field.transDate',
    format: ['html', 'excel', 'print'],
    noWrap: true,
    render: ({ row }) => showDate(row.transDate),
  },
  {
    id: 'repairDate',
    label: 'repair.field.repairDate',
    format: ['html', 'excel', 'print'],
    noWrap: true,
    render: ({ row }) => showDate(row.extra?.repairDate),
  },
  {
    id: 'repairDate',
    label: 'repair.field.repairTime',
    format: ['excel'],
    noWrap: true,
    render: ({ row }) => row.extra?.repairTime,
  },
  {
    id: 'type',
    label: 'repair.field.type',
    format: ['html', 'excel', 'print'],
    render: ({ row }) => row.extra?.typeName,
  },
  {
    id: 'fromLocationName',
    label: 'repair.field.dealerId',
    format: ['excel'],
  },
  {
    id: 'symptom',
    label: 'repair.field.symptom',
    format: ['excel'],
    render: ({ row }) => row.extra?.symptom,
  },
  {
    id: 'solution',
    label: 'repair.field.solution',
    format: ['excel'],
    render: ({ row }) => row.extra?.solution,
  },
  {
    id: 'tag',
    label: 'repair.field.tagId',
    format: ['html', 'excel', 'print'],
    render: ({ row }) => {
      if (!row.extra?.tagName) return null
      return row.extra?.tagName.join(', ')
    },
  },
  {
    id: 'support',
    label: 'repair.field.supportId',
    format: ['excel'],
    render: ({ row }) => row.extra?.supportName,
  },
  {
    id: 'tech',
    label: 'repair.field.techId',
    format: ['html', 'excel', 'print'],
    render: ({ row }) => {
      if (!row.extra?.techName) return null
      return row.extra?.techName.join(', ')
    },
  },
  {
    id: 'recipientContact',
    label: 'repair.field.recipientContact',
    format: ['html', 'excel', 'print'],
    render: ({ row }) => row.extra?.recipientContact,
  },
  {
    id: 'recipientPhone',
    label: 'field.contactPhone',
    format: ['excel'],
    render: ({ row }) => row.extra?.recipientPhone,
  },
  {
    id: 'recipientCellphone',
    label: 'field.contactCellphone',
    format: ['excel'],
    render: ({ row }) => row.extra?.recipientCellphone,
  },
  {
    id: 'recipientAddress',
    label: 'field.contactAddress',
    format: ['excel'],
    render: ({ row }) => showAddress(row.extra?.recipientAddress, message),
  },
  {
    id: 'receiptDate',
    label: 'repair.field.receiptDate',
    format: ['excel'],
    render: ({ row }) => {
      const receipts = row.extra?.receipts || []
      return receipts.map((item) => item.transDate).join(', ')
    },
  },
  {
    id: 'receiptNo',
    label: 'repair.field.receiptNo',
    format: ['excel'],
    render: ({ row }) => {
      const receipts = row.extra?.receipts || []
      return receipts.map((item) => item.receiptNo).join(', ')
    },
  },
  {
    id: 'receiptAddress',
    label: 'repair.field.receiptAddress',
    format: ['excel'],
    render: ({ row }) => {
      const receipts = row.extra?.receipts || []
      return receipts
        .map((item) => showAddress(item.receiptAddress, message))
        .join(', ')
    },
  },
  {
    id: 'receiptPrice',
    label: 'repair.field.receiptPrice',
    format: ['excel'],
    render: ({ row }) => {
      const receipts = row.extra?.receipts || []
      return receipts.map((item) => item.price).join(', ')
    },
  },
  {
    id: 'receiptMemo',
    label: 'repair.field.receiptMemo',
    format: ['excel'],
    render: ({ row }) => {
      const receipts = row.extra?.receipts || []
      return receipts.map((item) => item.memo).join(', ')
    },
  },
  {
    id: 'memo',
    label: 'field.memo',
    format: ['excel'],
    render: ({ row }) => row.extra?.memo,
  },
  {
    id: 'productVariantName',
    label: 'product.field.spu',
    format: ['excel'],
  },
  {
    id: 'sku',
    label: 'product.field.sku',
    format: ['excel'],
  },
  {
    id: 'productModel',
    label: 'repair.field.model',
    format: ['excel'],
    render: ({ row }) => row.itemExtra?.model,
  },
  {
    id: 'productSerialNo',
    label: 'repair.field.serialNo',
    format: ['excel'],
    render: ({ row }) => row.itemExtra?.serialNo,
  },
  {
    id: 'installDate',
    label: 'repair.field.installDate',
    format: ['excel'],
    render: ({ row }) => row.itemExtra?.repairDate,
  },
  {
    id: 'productMemo',
    label: 'repair.field.productMemo',
    format: ['excel'],
    render: ({ row }) => row.itemExtra?.memo,
  },
  {
    id: 'commission',
    label: 'repair.field.commission',
    format: ['excel'],
    render: ({ row }) => row.extra?.commission,
  },
  {
    id: 'collectAmount',
    label: 'repair.field.collectAmount',
    format: ['excel'],
    render: ({ row }) => parseInt(row.extra?.collectAmount || 0),
  },
  {
    id: 'status',
    label: 'field.status',
    format: ['html', 'excel', 'print'],
    renderHtml: ({ row }) => {
      if (!row.status) return ''
      return (
        <StatusChip
          label={`repair.status.${row.status}`}
          color={getStatusColor(row.status)}
        />
      )
    },
    render: ({ row }) => {
      if (!row.status) return ''
      return message({ id: `status.${row.status}` })
    },
  },
  // {
  //   id: 'partId',
  //   label: 'field.ticketId',
  //   format: ['excel'],
  // },
  {
    id: 'partTransDate',
    label: 'repair.field.partTransDate',
    format: ['excel'],
    render: ({ row }) =>
      row.ref?.map((item) => showDate(item.transDate)).join(', '),
  },
  {
    id: 'partName',
    label: 'repair.field.partName',
    format: ['excel'],
    render: ({ row }) =>
      row.ref?.map((item) => item.productVariantName).join(', '),
  },
  {
    id: 'partSku',
    label: 'repair.field.partSku',
    format: ['excel'],
    render: ({ row }) => row.ref?.map((item) => item.sku).join(', '),
  },
  {
    id: 'partQuantity',
    label: 'repair.field.partQuantity',
    format: ['excel'],
    render: ({ row }) => row.ref?.map((item) => item.quantity).join(', '),
  },
  {
    id: 'partPrice',
    label: 'repair.field.partPrice',
    format: ['excel'],
    render: ({ row }) => row.ref?.map((item) => item.price).join(', '),
  },
  // {
  //   id: 'receiptTransDate',
  //   label: 'repair.field.receiptTransDate',
  //   format: ['excel'],
  // },
  // {
  //   id: 'receiptNo',
  //   label: 'repair.field.receiptNo',
  //   format: ['excel'],
  // },
  // {
  //   id: 'receiptAddress',
  //   label: 'repair.field.receiptAddress',
  //   format: ['excel'],
  // },
  // {
  //   id: 'receiptMemo',
  //   label: 'repair.field.receiptMemo',
  //   format: ['excel'],
  // },
  {
    id: 'actions',
    align: 'right',
    noWrap: true,
    format: ['html'],
    renderHtml: ({ row }) => {
      const { isMigration = false } = row.extra || {}
      return (
        <>
          <LinkButton
            mr={2}
            disabled={isMigration || row.status === 'INACTIVE'}
            variant="icon"
            icon={<MdEdit />}
            href={`/repair/${row.id}/edit`}
          />
          {renderDeleteButton({
            module: 'repair',
            session,
            status: row.status,
            onSubmit: () =>
              handleDelete('repair', {
                session,
                app,
                id: row.id,
                hash: row.hash,
                action,
              }),
          })}
        </>
      )
    },
  },
]

export const handlers = ({
  setState,
  session,
  app,
  message,
  setFilterValues,
  setPagination,
  setDealers,
  setTechs,
  setRepairTypes,
  setRepairTags,
}) => ({
  handleLoad: async ({ pagination, filterValues } = {}) => {
    const filterId = 'repairList'
    if (!filterValues) {
      filterValues = session.state.filterMap[filterId] || []
      pagination = session.state.paginationMap[filterId] || []
    }
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setRepairTypes(resp.repairTypes)
    setRepairTags(resp.repairTags)
    setDealers(resp.dealers)
    setTechs(resp.techs)
    setState(resp.data)
    setPagination(resp.pagination)
    session.dispatch({
      type: FILTER_SET,
      key: filterId,
      filterValues,
      pagination,
    })
  },
  handlePrint: async ({ filterValues }) => {
    if (filterValues.length === 0) return

    const rows = await getExportData({ session, app, filterValues, message })
    const title = 'repair.title.list'
    const list = { columns: columns({ message }), rows }
    const content = [{ type: 'list', value: list }]
    printHtml({ title, content, message })
  },
  handleExport: async ({ filterValues, createExcel }) => {
    if (filterValues.length === 0) return

    const title = 'repair.title.list'
    const cols = columns({ message }).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const rows = await getExportData({ session, app, filterValues, message })
    createExcel({ message, title, cols, rows, filterValues })
  },
})

async function getData({ session, app, pagination = {}, filterValues = [] }) {
  const variables = {
    locationInput: { type: ['DEALER'] },
    staffInput: { type: ['TECHNICIAN'] },
    input: getDataInput({ filterValues, pagination }),
  }
  const query = `
    query($input: TicketQueryInput, $locationInput: LocationQueryInput, $staffInput: StaffQueryInput) {
      locations(input: $locationInput) {
        id
        name
      }
      staffs(input: $staffInput) {
        id
        name
      }
      repairTypes {
        id
        name
      }
      repairTags {
        id
        name
      }
      repairTicketCount(input: $input)
      repairTickets(input: $input) {
        id
        fromLocationName
        transDate
        extra
        status
        hash
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const { page, countPerPage } = pagination
  return {
    repairTypes: data.repairTypes,
    repairTags: data.repairTags,
    dealers: data.locations,
    techs: data.staffs,
    data: data.repairTickets,
    pagination: getPagination(page, countPerPage, data.repairTicketCount),
  }
}

async function getExportData({ session, app, filterValues, message }) {
  const variables = { input: getDataInput({ filterValues, joinItem: true }) }
  const query = `
    query($input: TicketQueryInput) {
      repairTickets(input: $input) {
        id
        ticketNo
        fromLocationName
        productVariantName
        sku
        quantity
        transDate
        extra
        itemExtra
        status
      }
      repairTicketParts(input: $input) {
        id
        parentId
        transDate
        extra
        productVariantName
        sku
        quantity
        price
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return formatExportData(data)
}

function formatExportData(data) {
  const { repairTickets, repairTicketParts } = data
  const partMap = repairTicketParts.reduce((result, item) => {
    const parts = result[item.parentId] || []
    parts.push(item)
    result[item.parentId] = parts
    return result
  }, {})
  return repairTickets.map((item) => {
    item.ref = partMap[item.id]
    return item
  })
}

// function formatExportData(tickets, message) {
//   const ticketMap = {}
//   const productMap = {}
//   const partMap = {}
//   const receiptMap = {}

//   tickets.forEach((item) => {
//     if (!ticketMap[item.id]) {
//       ticketMap[item.id] = {
//         id: item.id,
//         transDate: item.transDate,
//         fromLocationName: item.fromLocationName,
//         productVariantName: item.productVariantName,
//         sku: item.sku,
//         extra: item.extra,
//         status: item.status,
//       }
//     }

//     const products = productMap[item.id] || []
//     products.push({
//       productVariantName: item.productVariantName,
//       sku: item.sku,
//       productModel: item.itemExtra?.model,
//       productSerialNo: item.itemExtra?.serialNo,
//       productMemo: item.itemExtra?.memo,
//     })
//     productMap[item.id] = products

//     if (!partMap[item.id]) {
//       const parts = item.ref || []
//       partMap[item.id] = parts.map((part) => ({
//         partId: part.id,
//         partTransDate: showDate(part.transDate),
//         partName: part.productVariantName,
//         partSku: part.sku,
//         partQuantity: part.quantity,
//         partPrice: part.price,
//       }))
//     }

//     if (!receiptMap[item.id]) {
//       const receipts = item.extra?.receipts || []
//       receiptMap[item.id] = receipts.map((receipt) => ({
//         receiptTransDate: showDate(receipt.transDate),
//         receiptNo: receipt.receiptNo,
//         receiptAddress: showAddress(receipt.receiptAddress, message),
//         receiptMemo: receipt.memo,
//       }))
//     }
//   })

//   return Object.values(ticketMap).reduce((result, ticket) => {
//     const products = productMap[ticket.id]
//     const parts = partMap[ticket.id]
//     const receipts = receiptMap[ticket.id]
//     const productCnt = products.length
//     const partCnt = parts.length
//     const receiptCnt = receipts.length
//     const count = Math.max(productCnt, partCnt, receiptCnt)

//     for (let i = 0; i < count; i++) {
//       const record = i === 0 ? ticket : {}
//       const product = i < productCnt ? products[i] : {}
//       const part = i < partCnt ? parts[i] : {}
//       const receipt = i < receiptCnt ? receipts[i] : {}
//       result.push({ ...record, ...product, ...part, ...receipt })
//     }

//     return result
//   }, [])
// }

function getDataInput({ filterValues, pagination, joinItem = false }) {
  const id = filterValues.find(({ id }) => id === 'id')
  const repairDate = filterValues.find(({ id }) => id === 'repairDate')
  const transDate = filterValues.find(({ id }) => id === 'transDate')
  const contact = filterValues.find(({ id }) => id === 'contact')
  const phone = filterValues.find(({ id }) => id === 'phone')
  const dealer = filterValues.find(({ id }) => id === 'dealer')
  const itemModel = filterValues.find(({ id }) => id === 'model')
  const product = filterValues.find(({ id }) => id === 'product')
  const tech = filterValues.find(({ id }) => id === 'tech')
  const type = filterValues.find(({ id }) => id === 'type')
  const tag = filterValues.find(({ id }) => id === 'tag')
  const partName = filterValues.find(({ id }) => id === 'partName')
  const partSku = filterValues.find(({ id }) => id === 'partSku')
  const status = filterValues.find(({ id }) => id === 'status')
  const lockStatus = filterValues.find(({ id }) => id === 'lockStatus')
  const input = {
    joinItem,
    id: id?.value,
    transDate: transDate?.value,
    fromLocationId: dealer?.value.value,
    productVariantName: product?.value,
    status: status?.value.value || ['ACTIVE', 'PENDING'],
    extra: {
      type: type?.value.value,
      recipientContact: contact?.value,
      repairDate: repairDate?.value,
      techId: tech?.value.value,
      tagId: tag?.value.value,
      locked: lockStatus?.value.value,
    },
    ref: {},
    orderBy: [
      { key: 'transDate', sort: 'DESC' },
      { key: 'id', sort: 'DESC' },
    ],
  }
  if (itemModel?.value) {
    input.joinMeta = true
    input.meta = [{ type: 'REPAIR_MODEL', value: itemModel?.value }]
  }
  if (phone) {
    input.joinMeta = true
    const phoneMeta = {
      type: 'REPAIR_PHONE',
      value: getNumericValue(phone?.value),
    }
    if (input.meta) {
      input.meta.push(phoneMeta)
    } else {
      input.meta = [phoneMeta]
    }
  }
  if (partName) input.ref.productVariantName = partName?.value
  if (partSku) input.ref.sku = partSku?.value
  if (pagination) input.limit = getLimit(pagination)
  return input
}
