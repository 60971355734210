import React from 'react'
import { request } from 'utilities/request'
import { textFilter, dateFilter, selectFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { Link } from 'components/core'
import { filterLocations } from 'utilities/permission'
import { ALERT_ADD } from 'constants/actionType'

export const filters = ({ data }) => [
  {
    id: 'dept',
    label: 'field.dept',
    input: (props) =>
      selectFilter({ ...props, isSearchable: true, options: data.depts }),
  },
  {
    id: 'org',
    label: 'field.org',
    input: (props) =>
      selectFilter({ ...props, isSearchable: true, options: data.orgs }),
  },
  {
    id: 'dealer',
    label: 'field.dealer',
    input: textFilter,
  },
  {
    id: 'storage',
    label: 'field.storage',
    input: textFilter,
  },
  {
    id: 'transDate',
    label: 'field.date',
    input: dateFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'productName',
    label: 'field.productName',
    input: textFilter,
  },
  {
    id: 'isGift',
    label: 'dispatch.field.isGift',
    input: (props) =>
      selectFilter({
        ...props,
        options: [
          { value: true, label: props.message({ id: 'type.true' }) },
          { value: false, label: props.message({ id: 'type.false' }) },
        ],
      }),
  },
]

export const columns = ({ filterValues, action }) => [
  {
    id: 'locationName',
    label: 'field.dealer',
    format: ['html', 'excel'],
  },
  // {
  //   id: 'dealerCode',
  //   label: 'field.dealerCode',
  //   format: ['excel'],
  // },
  {
    id: 'productVariantName',
    label: 'field.productName',
    format: ['html', 'excel'],
  },
  {
    id: 'sku',
    label: 'field.sku',
    format: ['html', 'excel'],
  },
  {
    id: 'dispatchQuantity',
    label: 'field.dispatchQuantity',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          action.handleOpen({
            isDispatch: true,
            transDate: transDate?.value,
            ticketType: ['DISPATCH'],
            isGift: false,
            locationId: row.locationId,
            productVariantId: row.productVariantId,
          })
        }}
      >
        {row.dispatchQuantity}
      </Link>
    ),
    render: ({ row }) => row.dispatchQuantity,
  },
  {
    id: 'dispatchGiftQuantity',
    label: 'field.dispatchGiftQuantity',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          action.handleOpen({
            isDispatch: true,
            transDate: transDate?.value,
            ticketType: ['DISPATCH'],
            isGift: true,
            locationId: row.locationId,
            productVariantId: row.productVariantId,
          })
        }}
      >
        {row.dispatchGiftQuantity}
      </Link>
    ),
    render: ({ row }) => row.dispatchGiftQuantity,
  },
  {
    id: 'sellQuantity',
    label: 'field.sellQuantity',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          action.handleOpen({
            isSell: true,
            transDate: transDate?.value,
            ticketType: ['SELL', 'DEFER'],
            isGift: false,
            locationId: row.locationId,
            productVariantId: row.productVariantId,
          })
        }}
      >
        {row.sellQuantity}
      </Link>
    ),
    render: ({ row }) => row.sellQuantity,
  },
  {
    id: 'sellGiftQuantity',
    label: 'field.sellGiftQuantity',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          action.handleOpen({
            transDate: transDate?.value,
            ticketType: ['SELL', 'DEFER'],
            isGift: true,
            locationId: row.locationId,
            productVariantId: row.productVariantId,
          })
        }}
      >
        {row.sellGiftQuantity}
      </Link>
    ),
    render: ({ row }) => row.sellGiftQuantity,
  },
  {
    id: 'refundQuantity',
    label: 'field.refundQuantity',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          action.handleOpen({
            transDate: transDate?.value,
            ticketType: ['REFUND'],
            locationId: row.locationId,
            productVariantId: row.productVariantId,
          })
        }}
      >
        {row.refundQuantity}
      </Link>
    ),
    render: ({ row }) => row.refundQuantity,
  },
]

export const handlers = ({
  setState,
  session,
  app,
  message,
  setFilterValues,
  setPagination,
  setData,
}) => ({
  handleInit: async () => {
    const data = await getInit({ session, app })
    setData(data)
  },
  handleLoad: async ({ pagination, filterValues } = {}) => {
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
  },
  handleExport: async ({ filterValues, createExcel }) => {
    const title = 'report.title.dealerStock'
    const cols = columns({ message }).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows, filterValues })
  },
})

async function getInit({ session, app }) {
  const variables = {
    input: { type: ['DEALER_ORG', 'DEPT'] },
    staffInput: { type: ['SALES'] },
  }
  const query = `
    query($input: LocationQueryInput, $staffInput: StaffQueryInput) {
      staffs(input: $staffInput) {
        id
        name
      }
      locations(input: $input) {
        id
        name
        type
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const locations = filterLocations(app.state.staff, data.locations)
  const depts = []
  const orgs = []

  locations.forEach(({ id, name, type }) => {
    const result = { value: id, label: name }
    switch (type) {
      case 'DEALER_ORG':
        orgs.push(result)
        break
      case 'DEPT':
        depts.push(result)
        break
      default:
    }
  })

  return { depts, orgs, sales: data.staffs }
}

async function getData({ session, app, pagination = {}, filterValues = [] }) {
  if (filterValues.length === 0) return {}

  const transDate = filterValues.find(({ id }) => id === 'transDate')
  if (!transDate) {
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'warning', message: 'report.transDate.required' },
    })
    return {}
  }

  const variables = { input: getDataInput({ filterValues, pagination }) }
  const query = `
    query($input: LocationStockReportInput) {
      locationStockReportCount(input: $input)
      locationStockReports(input: $input) {
        locationId
        locationName
        productVariantId
        productVariantName
        sku
        dispatchQuantity
        dispatchGiftQuantity
        refundQuantity
        sellQuantity
        sellGiftQuantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const { page, countPerPage } = pagination
  return {
    data: data.locationStockReports,
    pagination: getPagination(
      page,
      countPerPage,
      data.locationStockReportCount,
    ),
  }
}

async function getExportData({ session, app, filterValues }) {
  const variables = { input: getDataInput({ filterValues, joinItem: true }) }
  const query = `
    query($input: LocationStockReportInput) {
      locationStockReports(input: $input) {
        locationName
        productVariantName
        sku
        dispatchQuantity
        dispatchGiftQuantity
        refundQuantity
        sellQuantity
        sellGiftQuantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.locationStockReports
}

function getDataInput({ filterValues, pagination }) {
  const dept = filterValues.find(({ id }) => id === 'dept')
  const org = filterValues.find(({ id }) => id === 'org')
  const dealer = filterValues.find(({ id }) => id === 'dealer')
  const storage = filterValues.find(({ id }) => id === 'storage')
  const transDate = filterValues.find(({ id }) => id === 'transDate')
  const productName = filterValues.find(({ id }) => id === 'productName')
  const isGift = filterValues.find(({ id }) => id === 'isGift')
  const input = {
    deptId: dept?.value.value,
    orgId: org?.value.value,
    storageName: storage?.value,
    locationName: dealer?.value,
    transDate: transDate?.value,
    productVariantName: productName?.value,
    isGift: isGift?.value.value,
    orderBy: [{ key: 'locationName' }, { key: 'productVariantName' }],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}
